import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const WriteFiles = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title =
      "PWA Fire - Write files to the local file system straight from the browser";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Mar 02 2025</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/write-files",
                title: "Write File API",
                text: "Create and save changes directly to files and folders on the user's device, example app would be a text editor"
              }}
            />
          </div>
          <h3>Create files, write and save changes</h3>
          <p className="quote">
            Create and save changes directly to files and folders on the user's
            device, example app would be a text editor.
          </p>
          <p>
            <DotIcon />
            Call the <b>createFile</b> method on pwa
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`const { handle } = await pwa.createFile({
    types: [
      {
        description: "Text files",
        accept: {
          "text/plain": [".txt"],
        },
      },
    ],
  });
`}</code>
          </pre>
          <p>
            <DotIcon />
            You can then use the <b>handle</b> above to save changes to the file
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`if(handle) {
    const res = await pwa.writeFile(handle, 'Hello World, I am a text file')
    // Do something with the response...
    const { ok, message } = res;
  }
`}</code>
          </pre>

          <p>
            <DotIcon />
            The <b>writeFile</b> method can receive the following options
          </p>
          <pre id="copy-code">
            <code className="language-javascript">{`interface FilePickerOptions {
  types: {
    description: string;
    accept: { [mimeType: string]: string[] };
  }[];
  id?: string;
  multiple?: boolean;
  suggestedName?: string;
  startIn?: string;
}
`}</code>
          </pre>
          <p className="quote">
            Learn more about this API in this detailed documentation by google
            devs{" "}
            <a href="https://developer.chrome.com/docs/capabilities/web-apis/file-system-access">
              here
            </a>
          </p>
        </section>
      </section>
    </article>
  );
};

export default WriteFiles;
